<template>
  <div>
    <Panel header="Cancelar/Suspender/Reincluir Consignação" class="mt-3">
      <div class="formgrid grid">
        <div class="field col-6 md:col-6">
          <Button
            label="Voltar"
            icon="pi pi-chevron-left"
            class="mt-2 mr-2 p-button-sm p-button-secondary"
            @click="$router.back()" />
        </div>
      </div>
      <form @submit.prevent="validate()">
        <div class="p-fluid formgrid grid">
          <div class="field col-6 md:col-6">
            <label for="rubrica">Rubrica</label>
            <InputText
              id="rubrica"
              v-model="consignacao.rubrica.nome"
              disabled />
          </div>
          <div class="field col-6 md:col-6">
            <label for="rubrica">Nº Contrato</label>
            <InputText
              id="rubrica"
              v-model="consignacao.numeroContrato"
              disabled />
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="field col-6 md:col-6">
            <label for="valor">Valor da Parcela</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <InputText
                id="valor"
                v-model="consignacao.valorParcela"
                disabled />
            </div>
          </div>
          <div class="field col-3 md:col-3">
            <label for="prazoTotal">Prazo</label>
            <InputText
              id="prazoTotal"
              v-model="consignacao.prazoTotal"
              disabled />
          </div>
          <div class="field col-3 md:col-3">
            <label for="situacao">Situação</label>
            <InputText
              id="situacao"
              v-model="exibirStatusConsignacao"
              disabled />
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="field col-4 md:col-4">
            <label for="tipo" class="required">Tipo</label>
            <Dropdown
              id="tipo"
              v-model="v$.tipo.$model"
              :options="tipos"
              optionLabel="nome"
              required
              :class="{ 'p-invalid': submitted && v$.tipo.$invalid }"
              placeholder="Selecione um tipo">
            </Dropdown>
            <div v-if="submitted && v$.tipo.required.$invalid" class="p-error">
              O campo Tipo é obrigatório
            </div>
          </div>
          <div class="field col-4 md:col-4">
            <label for="motivo" class="required">Motivo</label>
            <Dropdown
              id="motivo"
              v-model="v$.motivo.$model"
              :options="carregarListaMotivo"
              optionLabel="nome"
              placeholder="Selecione um motivo"
              required
              :class="{ 'p-invalid': submitted && v$.motivo.$invalid }"
              @change="changeMotivo()">
            </Dropdown>
            <div
              v-if="submitted && v$.motivo.required.$invalid"
              class="p-error">
              O campo Motivo é obrigatório
            </div>
          </div>
          <div class="field col-4 md:col-4">
            <label for="DataDocumento" class="required"
              >Data do documento</label
            >
            <Calendar
              id="DataDocumento"
              v-model="v$.dataDocumento.$model"
              :class="{
                'p-invalid':
                  submitted &&
                  (v$.dataDocumento.$invalid ||
                    dataDocumentoInvalida ||
                    dataDocumentoPosteriorDataAtual),
              }"
              :showIcon="true"
              dateFormat="dd/mm/yy" />
            <div
              v-if="submitted && v$.dataDocumento.required.$invalid"
              class="p-error">
              O campo Data do Documento é obrigatório
            </div>
            <div v-if="dataDocumentoInvalida" class="p-error">
              O campo Data do Documento está incorreto.
            </div>
            <div v-if="dataDocumentoPosteriorDataAtual" class="p-error">
              A Data do Documento está posterior a data de hoje.
            </div>
          </div>
        </div>
        <div v-if="isJudicial" class="p-fluid formgrid grid">
          <div class="field col-3 md:col-3">
            <label for="numeroOficio" class="required">Número do ofício</label>
            <InputText
              id="numeroOficio"
              v-model="v$.historicoConsignacao.numeroOficio.$model"
              :class="{
                'p-invalid':
                  submitted && v$.historicoConsignacao.numeroOficio.$invalid,
              }" />

            <div
              v-if="
                submitted &&
                v$.historicoConsignacao.numeroOficio.required.$invalid
              "
              class="p-error">
              O campo Número de Ofício é obrigatório
            </div>
          </div>

          <div class="field col-3 md:col-3">
            <label for="numeroProcesso" class="required"
              >Número do processo</label
            >
            <InputText
              id="numeroProcesso"
              v-model="v$.historicoConsignacao.numeroProcesso.$model"
              :class="{
                'p-invalid':
                  submitted && v$.historicoConsignacao.numeroProcesso.$invalid,
              }" />
            <div
              v-if="
                submitted &&
                v$.historicoConsignacao.numeroProcesso.required.$invalid
              "
              class="p-error">
              O campo Número de Processo é obrigatório
            </div>
          </div>

          <div class="field col-3 md:col-3">
            <label for="juizado" class="required">Juizado</label>
            <InputText
              id="juizado"
              v-model="v$.historicoConsignacao.juizado.$model"
              :class="{
                'p-invalid':
                  submitted && v$.historicoConsignacao.juizado.$invalid,
              }" />
            <div
              v-if="
                submitted && v$.historicoConsignacao.juizado.required.$invalid
              "
              class="p-error">
              O campo Juizado é obrigatório
            </div>
          </div>

          <div class="field col-3 md:col-3">
            <label for="nomeJuiz" class="required">Nome do Juiz</label>
            <InputText
              id="nomeJuiz"
              v-model="v$.historicoConsignacao.nomeJuiz.$model"
              :class="{
                'p-invalid':
                  submitted && v$.historicoConsignacao.nomeJuiz.$invalid,
              }" />
            <div
              v-if="
                submitted && v$.historicoConsignacao.nomeJuiz.required.$invalid
              "
              class="p-error">
              O campo Nome do Juiz é obrigatório
            </div>
          </div>
        </div>

        <div class="p-fluid formgrid grid col-12">
          <div class="field md:col-12">
            <label for="observacao" class="required">Observação</label>
            <Textarea
              id="observacao"
              v-model="v$.historicoConsignacao.observacao.$model"
              :class="{
                'p-invalid':
                  submitted && v$.historicoConsignacao.observacao.$invalid,
              }"
              :autoResize="true" />
            <div
              v-if="
                submitted &&
                v$.historicoConsignacao.observacao.required.$invalid
              "
              class="p-error">
              O campo Observação é obrigatório
            </div>
          </div>
        </div>

        <Button
          type="submit"
          label="Salvar"
          icon="pi pi-save"
          class="md:col-2"></Button>
      </form>
    </Panel>
    <modal-senha-servidor
      :exibir="exibirModalSenhaServidor"
      :metodoSalvar="salvar"
      :tipo="false"
      @mudarVisibilidade="exibirModalSenhaServidor = $event">
    </modal-senha-servidor>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import moment from 'moment'
import Consignacao from '@/domain/Consignacao.js'
import HistoricoConsignacao from '@/domain/HistoricoConsignacao.js'
import ConsignacaoService from '@/service/ConsignacaoService'
import ModalSenhaServidor from '@/components/shared/modal/modalSenhaServidor.vue'
import UtilService from '@/service/UtilService'
import { servidorStore } from '@/stores/servidor'

export default {
  components: {
    'modal-senha-servidor': ModalSenhaServidor,
  },

  setup() {
    const storeServidor = servidorStore()
    return { storeServidor, v$: UseVuelidate() }
  },

  data() {
    return {
      consignacao: new Consignacao(),
      historicoConsignacao: new HistoricoConsignacao(),
      exibirModalSenhaServidor: false,
      submitted: false,
      submitStatus: null,
      tipo: null,
      motivo: '',
      dataDocumento: null,
      dataDocumentoInvalida: false,
      dataDocumentoPosteriorDataAtual: false,
      dataAtual: null,
      situacao: null,
      isJudicial: false,
      id: this.$route.params.idConsignacao,
      tipos: [],
      motivos: [
        { nome: 'JUDICIAL', code: 'JUDICIAL' },
        { nome: 'QUITAÇÃO', code: 'QUITACAO' },
      ],
    }
  },

  computed: {
    exibirStatusConsignacao() {
      return this.exibirNomeFormatadoStatusConsignacao()
    },
    carregarListaMotivo() {
      return this.carregarListaMotivoPorTipoStatusSelecionado()
    },
  },

  created() {
    this.service = new ConsignacaoService(this.$http)
    this.utilService = new UtilService(this.$http)
    this.service
      .getConsignacaoById(this.id)
      .then((res) => {
        this.consignacao = res
        if (
          this.consignacao.statusConsignacao === 'PENDENTE' ||
          this.consignacao.statusConsignacao === 'NAO_DESCONTADA' ||
          this.consignacao.statusConsignacao === 'FORA_MARGEM'
        ) {
          this.tipos = [
            {
              nome: 'CANCELAMENTO',
              code: 'CANCELADA',
            },
          ]
        } else if (
          this.consignacao.statusConsignacao === 'ATIVA' ||
          this.consignacao.statusConsignacao === 'DESCONTO_A_MENOR'
        ) {
          this.tipos = [
            {
              nome: 'CANCELAMENTO',
              code: 'SOLICITADO_CANCELAMENTO',
            },
            { nome: 'SUSPENSÃO', code: 'SOLICITADA_SUSPENSAO' },
          ]
        } else if (this.consignacao.statusConsignacao === 'SUSPENSA') {
          this.tipos = [
            {
              nome: 'CANCELAMENTO',
              code: 'CANCELADA',
            },
            { nome: 'INCLUSÃO', code: 'PENDENTE' },
          ]
        } else if (
          this.consignacao.statusConsignacao === 'SOLICITADA_SUSPENSAO'
        ) {
          this.tipos = [
            {
              nome: 'CANCELAMENTO',
              code: 'CANCELADA',
            },
            { nome: 'INCLUSÃO', code: 'ATIVA' },
          ]
        }
      })
      .catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Ocorreu um erro. Tente novamente!',
          life: 10000,
        })
      })
  },

  mounted() {
    this.carregaDataAtual()
  },

  validations() {
    return {
      historicoConsignacao: {
        numeroOficio: { required },
        numeroProcesso: { required },
        nomeJuiz: { required },
        juizado: { required },
        observacao: { required },
      },
      tipo: { required },
      motivo: { required },
      dataDocumento: { required },
    }
  },

  methods: {
    exibirNomeFormatadoStatusConsignacao() {
      if (this.consignacao.statusConsignacao === 'PENDENTE') {
        return 'Pendente'
      } else if (this.consignacao.statusConsignacao === 'ATIVA') {
        return 'Ativa'
      } else if (this.consignacao.statusConsignacao === 'SUSPENSA') {
        return 'Suspensa'
      } else if (this.consignacao.statusConsignacao === 'CANCELADA') {
        return 'Cancelada'
      } else if (this.consignacao.statusConsignacao === 'QUITADA') {
        return 'Quitada'
      } else if (this.consignacao.statusConsignacao === 'JUDICIAL') {
        return 'Judicial'
      } else if (this.consignacao.statusConsignacao === 'QUITACAO') {
        return 'Quitação'
      } else if (
        this.consignacao.statusConsignacao === 'SOLICITADO_CANCELAMENTO'
      ) {
        return 'Cancelada'
      } else if (
        this.consignacao.statusConsignacao === 'SOLICITADA_SUSPENSAO'
      ) {
        return 'Suspensa'
      } else if (this.consignacao.statusConsignacao === 'MOVIMENTO_PARCIAL') {
        return 'Mov. Parcial'
      } else if (
        this.consignacao.statusConsignacao === 'AGUARDANDO_SUPERVIDOR'
      ) {
        return 'Aguardando Supervisor'
      } else if (
        this.consignacao.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'
      ) {
        return 'Não Aprovada Supervisor'
      } else if (this.consignacao.statusConsignacao === 'BAIXADA') {
        return 'Baixada'
      } else if (this.consignacao.statusConsignacao === 'NAO_DESCONTADA') {
        return 'Não Descontada'
      } else if (this.consignacao.statusConsignacao === 'FORA_MARGEM') {
        return 'Fora da Margem'
      }
    },

    carregarListaMotivoPorTipoStatusSelecionado() {
      if (this.tipo != null) {
        if (this.tipo.nome === 'SUSPENSÃO' || this.tipo.nome === 'INCLUSÃO') {
          return [{ nome: 'JUDICIAL', code: 'JUDICIAL' }]
        } else if (this.tipo.nome === 'CANCELAMENTO') {
          return [
            { nome: 'JUDICIAL', code: 'JUDICIAL' },
            { nome: 'QUITAÇÃO', code: 'QUITACAO' },
          ]
        }
      } else {
        return [
          { nome: 'JUDICIAL', code: 'JUDICIAL' },
          { nome: 'QUITAÇÃO', code: 'QUITACAO' },
        ]
      }
    },

    validate() {
      this.submitted = true
      this.v$.historicoConsignacao.$touch()

      if (this.validaDataDocumento()) return
      if (this.isDataDocumentoEPosteriorDataAtual()) return

      if (this.motivo.code == 'JUDICIAL') {
        if (
          this.v$.historicoConsignacao.nomeJuiz.$invalid ||
          this.v$.historicoConsignacao.juizado.$invalid ||
          this.v$.historicoConsignacao.numeroProcesso.$invalid ||
          this.v$.historicoConsignacao.numeroOficio.$invalid ||
          this.v$.historicoConsignacao.observacao.$invalid ||
          this.v$.tipo.$invalid ||
          this.v$.motivo.$invalid ||
          this.v$.dataDocumento.$invalid
        ) {
          return
        } else {
          this.exibirModalSenhaServidor = true
        }
      } else {
        if (
          this.v$.historicoConsignacao.observacao.$invalid ||
          this.v$.tipo.$invalid ||
          this.v$.motivo.$invalid ||
          this.v$.dataDocumento.$invalid
        ) {
          return
        } else {
          this.exibirModalSenhaServidor = true
        }
      }
    },

    validaDataDocumento() {
      if (!moment(this.dataDocumento).isValid()) {
        this.dataDocumentoInvalida = true
        return true
      } else {
        this.dataDocumentoInvalida = false
        return false
      }
    },

    isDataDocumentoEPosteriorDataAtual() {
      if (moment(this.dataDocumento).isAfter(this.dataAtual)) {
        this.dataDocumentoPosteriorDataAtual = true
        return true
      } else {
        this.dataDocumentoPosteriorDataAtual = false
        return false
      }
    },

    salvar() {
      this.atribuirDados()
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )
      this.service
        .cancelarConsignacaoConsignataria(
          this.consignacao.id,
          consignataria.id,
          this.historicoConsignacao,
        )
        .then(
          (res) => {
            this.storeServidor.dados = res.data.matricula
            this.exibeToast('success')
            this.limparCampos()
            this.$router.push({ name: 'consignacoes' })
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
          },
        )
    },

    atribuirDados() {
      this.historicoConsignacao.alteradoPor = this.alteradoPor
      this.historicoConsignacao.cadastradoPor = this.cadastradoPor
      this.historicoConsignacao.consignacao = this.consignacao
      this.historicoConsignacao.tipo = this.tipo.code
      this.historicoConsignacao.motivo = this.motivo.code
      this.historicoConsignacao.dataDocumento = this.formatarDataParaSalvar(
        this.dataDocumento,
      )
    },

    carregaDataAtual() {
      this.utilService.devolveMesAnoAtual().then(
        (res) => {
          let data = res.mes + '/' + res.dia + '/' + res.ano
          this.dataAtual = moment(data).toDate()
        },
        (err) => {
          if (err) {
            this.exibeToast('error-data')
          }
        },
      )
    },

    limparCampos() {
      this.submitted = false
      this.v$.$reset()
      this.consignacao = new Consignacao()
    },

    exibeToast(tipo) {
      if (tipo === 'success') {
        this.$toast.add({
          severity: 'success',
          summary: 'Cancelado/Suspensa com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'error') {
        this.$toast.add({
          severity: 'error',
          summary:
            'Erro ao tentar cancelar/suspender. Entre em contato com o suporte.',
          life: 10000,
        })
      } else if (tipo === 'error-data') {
        this.$toast.add({
          severity: 'error',
          summary: 'Erro ao tentar trazer a data atual.',
          life: 10000,
        })
      }
    },

    changeMotivo() {
      if (this.motivo.nome == 'JUDICIAL') {
        this.isJudicial = true
      } else this.isJudicial = false
    },

    formatarDataParaSalvar(value) {
      let data_americana = moment(value, 'YYYY-MM-DD').toDate()
      return data_americana
    },

    moment: function () {
      return moment()
    },
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}
</style>
